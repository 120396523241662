import React from 'react';
import './styles.css';
import { motion } from "framer-motion"

function App() {
  return (
    <div style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
    <motion.svg 
      height={120}
      viewBox="0 0 2375 1932"
      fill="black"
    >
      <motion.path
        transition={{
          duration: 1.2,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
        initial={{
          scale: 0.76,
          rotate: 0,
          filter: 'drop-shadow(0px 0px 0px #fff)'
        }}
        // animation end state
        animate={{
          scale: 0.8,
          rotateZ: 2,
          skewX: 2,
          filter: 'drop-shadow(0px 80px 0px #50ae32)'
        }}   
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={10}
        d="M149.731,1822.41l737.297,-568.943l263.616,287.451l1136.65,-938.323l87.242,-602.6l-9.221,13.614l0.417,-2.647l-1199.8,1351.02l-277.025,-333.442l-888.915,888.821l8.912,8.188l6.006,5.512l134.813,-108.647Zm-18.758,-9.182l762.26,-614.286l260.353,301.129l1102.97,-921.947l80.361,-506.241l-1171.45,1319.11l-277.801,-334.368l-756.691,756.608Z"
      />
      </motion.svg>
      <p style={{fontWeight: 'bold'}}><a href="https://www.abatop.de">www.abatop.de</a></p>
    </div>
  );
}

export default App;
